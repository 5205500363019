import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "routers/routes";
import { useStyles } from "./styles";

import icLogout from "asset/img/ic-logout.svg";

import { Owner } from 'models/owner';
import * as ownerServices from 'services/owner';
import * as _ from 'lodash';

const MenuCBL = ({ refreshSideMenu }) => {
  const classes = useStyles();
  const history = useHistory();

  const [dataListOwner, setDataListOwner] = useState<Owner[]>([]);
  const [totalOwner, setTotalOwner] = useState(0);
  const [isRefresh, setisRefresh] = useState(false);
  const [ownerProps, setOwnerProps] = useState({ pageSize: 20, page: 1, keyword: '', isRefresh: false })

  const pathName = history.location.pathname;

  const handleLogout = async () => {
    localStorage.clear();
    window.location.href = window.location.origin + routes.authentication;
  };

  useEffect(() => {
    setDataListOwner([])
    setisRefresh(!isRefresh)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSideMenu])

  useEffect(() => {
    if (ownerProps.page === 1) {
      setDataListOwner([])
      setTotalOwner(0)
    }
    getDataListOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerProps, isRefresh]);

  const getDataListOwner = async () => {
    await ownerServices.getListData(ownerProps)
      .then(r => {
        let _dataListOwner = [...dataListOwner]
        _dataListOwner = dataListOwner.concat(r.data);
        setDataListOwner(_dataListOwner)
        setTotalOwner(r.totalCount)
      }).catch(() => null)
  }

  const handleChangePage = (item) => () => {
    history.push(routes.admin.owner.vessel.replace(':ownerId', item.id))
  }

  // const handleChangeKeyWord = _.debounce(e => {
  //   setDataListOwner([])
  //   setTotalOwner(0)
  //   const keyword = e?.target?.value || '';
  //   ownerProps.page = 1;
  //   setOwnerProps({ ...ownerProps, keyword })
  // }, 300)

  const handleScrollEndOption = _.debounce(async (e) => {
    let element = e.target;
    let atBottom = (element.scrollHeight - element.scrollTop - 10) <= element.clientHeight

    if (atBottom && totalOwner && (ownerProps.page * ownerProps.pageSize < totalOwner)) {
      ownerProps.page = ownerProps.page + 1;
      setOwnerProps({ ...ownerProps })
    }

  }, 200)

  return (
    <Grid className="container">
      <List
        component="nav"
        className={classes.root}
        subheader={<ListSubheader component="div" ><p>Master Data</p></ListSubheader>}
      >
        <ListItem
          button
          onClick={() => history.push(routes.admin.vessel)}
          className={clsx(
            pathName.includes(routes.admin.vessel) && classes.itemActive
          )}
        >
          <ListItemText primary="Vessel" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push(routes.admin.user)}
          className={clsx(
            pathName.includes(routes.admin.user) && classes.itemActive
          )}
        >
          <ListItemText primary="User" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push(routes.admin.owner.default)}
          className={clsx(
            pathName === routes.admin.owner.default && classes.itemActive
          )}
        >
          <ListItemText primary="Owner" />
        </ListItem>
      </List>
      <List
        component="nav"
        className={classes.rootOwner}
        subheader={<ListSubheader component="div">List Of Owners</ListSubheader>}
      >
        {/* <Grid className={classes.searchOwner}>
          <SearchOwners defaultValue={ownerProps.keyword} onChange={handleChangeKeyWord} placeholder={`Search owners`} />
        </Grid> */}
        <Grid className={classes.ownerContent} onScroll={handleScrollEndOption}>
          {
            dataListOwner?.map(item => {
              return (
                <ListItem button key={item.id}
                  className={clsx(
                    pathName === routes.admin.owner.vessel.replace(':ownerId', item.id.toString()) && classes.itemActive
                  )}
                  onClick={handleChangePage(item)}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              )
            })
          }
        </Grid>
      </List>
      <List component="nav" className={classes.rootUser}>
        {/* <ListItem button>
          <ListItemText
            primary="Change Password"
            // onClick={() => history.push(routes.resetPassword)}
          />
        </ListItem> */}
        <ListItem button>
          <img src={icLogout} alt="logout" />
          <ListItemText
            primary="Logout"
            onClick={handleLogout}
          />
        </ListItem>
      </List>
    </Grid>
  );
};

export default MenuCBL;


import { AppAPIInstance } from '../configApi';
import { API } from 'config/constans';

export const getListData = async (params: { [key in string]: any }): Promise<any> => {
  return AppAPIInstance.post(API.OWNER.SEARCH, params);
};

export const createOwner = async (data): Promise<any> => {
  return AppAPIInstance.post(API.OWNER.CREATE, data);
};

export const updateOwner = async (data): Promise<any> => {
  return AppAPIInstance.put(API.OWNER.UPDATE, data);
};

export const updateStatusItem = async (data): Promise<any> => {
  return AppAPIInstance.put(API.OWNER.UPDATE_STATUS, data);
};


import { createStyles, makeStyles } from "@material-ui/core/styles";

// import highlightBg from "asset/img/highlight_bg.png";
const sideBarWidth = 230;
const sideBarHideWidth = 51;
export const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: "flex",
        height: "100vh",
      },
      sideBar: {
        height: "100%",
        maxHeight: "100vh",
        minWidth: sideBarWidth,
        position: "relative",
        background: "#0F292E",
        // padding: "23px 0px 0px 18px",
        boxSizing: "border-box",
        "& .logo": {
          padding: "23px 18px 10px 18px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .iconMenu": {
          cursor: "pointer",
        },
        "& .footer": {
          width: sideBarWidth,
          position: "fixed",
          bottom: 0,
        },
        "& .profile": {
          marginBottom: 0,
        },
        "& .menu": {
          height: "calc(100% - 132px)",
          "& .container": {
            height: "100%",
          },
        },
        // "&::before": {
        //   content: "'a'",
        //   display: "block",
        //   position: "absolute",
        //   background:
        //     "radial-gradient(105.73% 105.73% at 50% 50%, #6C60FF 0%, #3725FF 54.69%, rgba(20, 0, 255, 0.15) 100%)",
        //   height: 70,
        //   top: "110px",
        //   left: "60%",
        //   transform: "translateX(-50%)",
        //   width: 200,
        //   filter: "blur(100px)",
        // },
      },
      mainContent: {
        width: `calc(100% - ${sideBarWidth}px)`,
        background: "#0B2125",
        padding: "26px 36px",
      },
      hideContent: {
        width: `calc(100% - ${sideBarHideWidth}px)`,
        background: "#0B2125",
        padding: "26px 36px",
      },
      hideBar: {
        height: "100%",
        maxHeight: "100vh",
        minWidth: sideBarHideWidth,
        position: "relative",
        background: "#0F292E",
        "& .logo": {
          padding: "23px 18px 10px 18px",
          boxSizing: "border-box",
          "& div": {
            display: "none",
          },
        },
        "& .iconMenu": {
          cursor: "pointer",
          // transform: "rotate(90deg)",
        },
        "& .footer": {
          display: "none",
        },
        "& .profile": {
          display: "none",
        },
        "& .menu": {
          display: "none",
        },
      },
    }),
  {
    name: "LayoutCms",
    index: 1,
  }
);

import moment from 'moment';

export const convertTimeToUTC = (date: Date | string) => {
  if (!date) return null;
  if ('string' === typeof date) date = date.replace(/Z$/i, '');
  return new Date(moment.utc(date).local().toString())
}

// format ex: September 30, 2021
export const formatDate = (day?: string) => {
  if (!day) return;
  var date = day.slice(0, 10).split("-");
  date[1] = date[1] === "01" ? "January" :
    date[1] === "02" ? "February" :
      date[1] === "03" ? "March" :
        date[1] === "04" ? "April" :
          date[1] === "05" ? "May" :
            date[1] === "06" ? "June" :
              date[1] === "07" ? "July" :
                date[1] === "08" ? "August" :
                  date[1] === "09" ? "September" :
                    date[1] === "10" ? "October" :
                      date[1] === "11" ? "November" : "December";
  return `${date[1]} ${date[2]}, ${date[0]}`;
}

export const convertTimeUTCToLocal = (time: Date) => {
  const date = new Date(time);
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

export const add1Month = (date: Date | string) => {
  if (!date) return null;
  return new Date(moment(date).add(1, 'months').toString())
}

export const getSuffix = (num) => {
  const suffixes = {
    '1': 'st',
    '21': 'st',
    '31': 'st',
    '2': 'nd',
    '22': 'nd',
    '3': 'rd',
    '23': 'rd'
  };
  return suffixes[num] || 'th';
};

export const formatDateTimeToDayName = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return (moment(date).format(`DD-MMM HH:mm UTC`) + '<br/>\n(Average Instantaneous rate last 24 hours)')
}
export const formatDateTimeToDay = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`) + '<br/>\n(Average Instantaneous rate last 24 hours)'
}
export const formatDateTimeToMinute = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`)
}

export const formatDateTimeToTime = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return (moment(date).format(`DD-MMM HH:mm UTC`) + '<br/>\n(Average Instantaneous rate last 1 hour)')
}

export const formatDateTimeToTimeLineChart = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return (moment(date).format(`DD-MMM HH:mm UTC`) + '<br/>\n(Average Instantaneous rate last 1 hour)')
}

export const formatDateTimeToTimeStep = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(` MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`)
}

export const formatDateTimeToDateTime = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(` MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`)
}

export const formatDateTimeToMonth = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(` MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`)
}

export const formatDateTimeToYear = (date: Date) => {
  if (!date) return null;
  // return moment(date).format(`DD`) + getSuffix(moment(date).format(`D`)) + " " + moment(date).format(`MMM / HH:mm UTC`)
  return moment(date).format(`DD-MMM HH:mm UTC`)
}

export const formatLabelType = (type: number, time: Date) => {
  switch (type) {
    case 1:
      return formatDateTimeToDateTime(time)
    case 2:
      return formatDateTimeToMinute(time)
    case 3:
      return formatDateTimeToTime(time)
    case 4:
      return formatDateTimeToTimeStep(time)
    case 5:
      return formatDateTimeToTimeStep(time)
    case 6:
      return formatDateTimeToDay(time)
    case 7:
      return formatDateTimeToDay(time)
    case 8:
      return formatDateTimeToMonth(time)
    case 9:
      return formatDateTimeToYear(time)
    default:
      break;
  }
}

export const hourDifference = (firstDate, secondDate): number => {
  if (firstDate && secondDate) {
    let tmpreturn = moment(secondDate).diff(moment(firstDate), 'h')
    return Math.abs(Number(tmpreturn))
  }
  return 0;
};

export const dateFormatter = (date, format): Date | string => {
  if (format) {
    if (date)
      return moment(date).format(format)
    else
      return moment().format(format)
  } else {
    return '-'
  }
};

export const utcFromDateTimeAndOffset = (date, time, offset): any => {

  let isOffsetMinus = offset?.includes('-')
  let offsetElements = offset?.split(':')
  let offsetHours = offsetElements[0]?.replace('-', '')?.replace('+', '')

  if (date && time && offset) {
    if (isOffsetMinus)
      return moment(`${date} ${time}`).add('h', offsetHours).format('YYYY-MM-DDTHH:mm:ss')
    else
      return moment(`${date} ${time}`).subtract('h', offsetHours).format('YYYY-MM-DDTHH:mm:ss')
  } else {
    return '-'
  }
};

import { createStyles, makeStyles } from "@material-ui/core/styles";
import theme from "config/theme";

interface propsButtonStyle {
  width?: number;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        fontFamily: "Roboto ",
        textTransform: "capitalize",
        background: "rgba(20, 211, 165, 0.25)",
        color: theme.titleColor,
        borderRadius: 5,
        height: 40,
        width: (props: propsButtonStyle) => props.width,
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "100%",
        letterSpacing: 0.5,
        cursor: "pointer",
        "&:hover": {
          background: "rgba(20, 211, 165, 0.25)",
        },
      },
      btnLarge: {
        padding: "12px 60px",
        width: "max-content",
      },
      btnMedium: {
        padding: "8px 16px",
      },
      btnSmall: {
        padding: "6px 8px",
        "& .MuiButton-root.Mui-disabled": {
          color: "#FFFFFF !important",
        },
      },
      btnSmallLightBg: {
        background: theme.bgButtonLightColor,
      },
      btnSmallLong: {
        width: 153,
      },
      btnBack: {
        background: "rgba(255, 255, 255, 0.15)",
        "& img": {
          marginRight: 5,
        },
      },
      btnSave: {
        background: "rgba(20, 211, 165, 0.25)",
        "& img": {
          marginLeft: 6,
        },
      },
      btnClose: {
        height: 24,
        width: 73,
        background: "rgba(255, 255, 255, 0.15)",
        "& img": {
          marginRight: 6,
        },
        "& .txt": {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "14px",
          fontFamily: "Roboto !important",
        },
      },
    }),
  {
    name: "Buttons",
    index: 1,
  }
);

export default useStyles;

import { Grid } from "@material-ui/core";
import Logo from "components/Logo";
import MenuCBL from "components/Menu";
import Profiles from "components/Menu/Profiles";
import Images from "config/images";
import { memo, Suspense, lazy, useState } from "react";
import { useStyles } from "./styles";
import { Redirect, Switch, Route } from "react-router-dom";
import { LoadingFullPage } from "components";
import { routes } from "routers/routes";
import clsx from "clsx";

const Vessel = lazy(() => import("modules/Admin-User/Vessel"));
const User = lazy(() => import("modules/Admin-User/User"));
const Owner = lazy(() => import("modules/Admin-User/Owner"));

// Need to remove this code
// const OwnerVessel = lazy(() => import("modules/Owner/Vessel"));

const OwnerVesselListing = lazy(() => import("modules/Admin-User/Owner-Vessel/VesselTableMap"));

const NotFoundPage = lazy(() => import("components/NotFoundPage"));

interface LayoutCmsProps { }
const LayoutCms = memo((props: LayoutCmsProps) => {
  const classes = useStyles();
  const [hide, setHide] = useState(false);
  const [refreshSideMenu, setrefreshSideMenu] = useState(false);

  return (
    <Grid className={classes.root}>
      <Grid className={clsx(hide ? classes.hideBar : classes.sideBar)}>
        <Grid className="logo">
          <Logo type="header" />
          <img
            src={Images.icMenu.default}
            alt=""
            className="iconMenu"
            onClick={() => setHide((hide) => !hide)}
          />
        </Grid>
        <Grid className="profile">
          <Profiles />
        </Grid>
        <Grid className="menu">
          <MenuCBL refreshSideMenu={refreshSideMenu} />
        </Grid>
      </Grid>
      <Grid className={clsx(hide ? classes.hideContent : classes.mainContent)}>
        <Suspense
          fallback={<LoadingFullPage isShow={true} isFullPage={true} />}
        >
          <Switch>
            <Redirect exact from={routes.default} to={routes.admin.default} />
            <Redirect
              exact
              from={routes.admin.default}
              to={routes.admin.vessel}
            />
            <Route path={routes.admin.vessel} component={Vessel} />
            <Route path={routes.admin.user} component={User} />
            <Route path={routes.admin.owner.vessel} component={OwnerVesselListing} />
            <Route path={routes.admin.owner.default} render={(props) => <Owner {...props} setrefreshSideMenu={setrefreshSideMenu} />} />
            <Route path={routes.notFound} component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Grid>
    </Grid>
  );
});

export default LayoutCms;

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import icShowPassInput from "asset/img/ic-show-pass.svg";
import icOffShowPass from "asset/img/ic-off-showpass.svg";
import clsx from "clsx";
import { useState, memo } from "react";
import useStyles from "./styles";

const Inputs = memo((props: any) => {
  const classes = useStyles(props);
  const [toggleEyes, setToggleEyes] = useState(false);
  const {
    title,
    type,
    placeholder,
    name,
    register,
    errorMessage,
    required,
    value,
    defaultValue,
    showEyes,
    isValidatePassword,
    handleSetError,
    readOnly = false,
    min,
    inputType,
    rows = 1,
    background,
    pHSize,
    inputRef,
    ...rest
  } = props;

  const handleClick = () => {
    setToggleEyes(!toggleEyes);
  };


  const { ref: refInput, ...inputProps } = inputRef || { ref: null };
  return (
    <FormControl
      fullWidth
      classes={{ root: clsx(classes.rootFormControl, classes.container) }}
    >
      <Typography classes={{ root: classes.label }}>{title}</Typography>
      <TextField
        type={toggleEyes ? "text" : type}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        multiline={rows > 1 ? true : false}
        rows={rows}
        fullWidth
        variant="filled"
        defaultValue={defaultValue}
        classes={{
          root: clsx(
            classes.rootTextField,
            inputType === "pr" && classes.inputPr,
            background === "0.25" && classes.bg25
          ),
        }}
        inputProps={{ readOnly, min, step: "any" }}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.inputTextfield,
          },
          endAdornment: showEyes && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClick}
                className={classes.buttonIcon}
              >
                {!toggleEyes ? (
                  <Grid className={classes.icOffShowPass}>
                    <img src={icOffShowPass} alt="icShowPass" />
                  </Grid>
                ) : (
                  <img src={icShowPassInput} alt={icShowPassInput} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...inputProps}
        inputRef={refInput}
        {...rest}
      />
      {errorMessage && (
        <Typography classes={{ root: classes.textError }}>
          {errorMessage}
        </Typography>
      )}
    </FormControl>
  );
});
export default Inputs;

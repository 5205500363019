import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import icLogoLogin from "asset/img/ic-logo-login.svg";
import icLogoHeader from "asset/img/ic-logo-header.svg";
interface logoProps {
  type: string;
}
const Logo = (props: logoProps) => {
  const { type } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      {type === "login" && <img src={icLogoLogin} alt="iclogologin" />}
      {type === "header" && <img src={icLogoHeader} alt="icLogoHeader" />}
    </Grid>
  );
};

export default Logo;

import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import Routes from "routers";
import styled from "styled-components";
import { sleep } from 'helpers';

import { ACCESS_TOKEN } from "config/constans";
import { reducerType } from "store/reducers";
import { GET_PROFILE_REQUEST } from 'store/reducers/profile/actionTypes';
import { useSelector, useDispatch } from 'react-redux';
import { IProfileState } from 'store/reducers/profile';
import { startWatchStorage } from 'services/configApi/configVinovaApi';
import { setToken } from 'services/configApi';
import { LoadingFullPage, PopupMessageError } from "components";
import { ErrorBoundary } from "components/Error";

const AppContainer = styled.div`
  height: 100%;
`;

const mapStateToProps = (state: reducerType) => {
  return {
    isLoading: state.global.isLoading,
  };
};

type AppProps = {
  history: History;
  isLoading: boolean;
};

const App = memo((props: AppProps) => {
  const dispatch = useDispatch()
  const profile: IProfileState = useSelector((state: reducerType) => state.profile)
  const token = localStorage.getItem(ACCESS_TOKEN);
  const [isLogin, setIsLogin] = useState(!!token);
  const [isFirstApp, setIsFirstApp] = useState(false)
  const [isRenderApp, setIsRenderApp] = useState(false);

  if (!!token) {
    setToken(token);
    if (!isLogin) setIsLogin(true);
    if (!profile.email && !isFirstApp) {
      setIsFirstApp(true);
      dispatch({ type: GET_PROFILE_REQUEST })
    }
  }

  useEffect(() => {
    if (!!token) {
      setToken(token);
      if (!isLogin) setIsLogin(true);
    }
  }, [token, isLogin])

  useEffect(() => {
    const checkRenderApp = async () => {
      await sleep(1000);
      if (isFirstApp && token && profile.email) {
        setIsRenderApp(true)
      }
      if (!isFirstApp && !token) {
        setIsRenderApp(true)
      }
    }
    checkRenderApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstApp, profile])


  useEffect(() => {
    startWatchStorage();
  }, []);

  if (!isRenderApp) {
    return <LoadingFullPage isFullPage={false} isShow={true} />
  }

  return (
    <ErrorBoundary>
      <AppContainer>
        <LoadingFullPage isFullPage={false} isShow={props.isLoading} />
        <PopupMessageError />
        <ConnectedRouter history={props.history}>
          <Routes isLoggedIn={isLogin} profile={profile} />
        </ConnectedRouter>
      </AppContainer>
    </ErrorBoundary>
  );
});

export default connect(mapStateToProps)(App);

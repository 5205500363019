import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import themeConfig from "config/theme";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        color: themeConfig.titleColor,
        fontSize: 16,
      },
      colorNormal: {
        color: themeConfig.titleColor,
      },
      size14: {
        fontSize: 14,
      },
      size18: {
        fontSize: 18,
      },
      size20: {
        fontSize: 20,
      },
      size22: {
        fontSize: 22,
      },
      size24: {
        fontSize: 24,
      },
      size30: {
        fontSize: 30,
      },
      size32: {
        fontSize: 32,
      },
      sizeLarge: {
        fontSize: 14,
      },
      fontWeight500: {
        fontWeight: 500,
      },
      fontWeight300: {
        fontWeight: 300,
      },
      fontWeight600: {
        fontWeight: 600,
      },
      fontWeightBold: {
        fontWeight: "bold",
      },
    }),
  {
    name: "Texts",
    index: 1,
  }
);

export default useStyles;

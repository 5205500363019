import { memo } from 'react';
import {
  Button, Dialog,
  DialogContent, Grid
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { reducerType } from 'store/reducers';
import * as actionGlobal from 'store/reducers/global/actionTypes';

import useStyles from './styles';

const PopupMessageError = memo(() =>  {
  const messageError = useSelector((state: reducerType) => state.global.messageError)
  const btnName = useSelector((state: reducerType) => state.global.btnNameShow)

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({  type: actionGlobal.SET_MESSAGE_ERROR, payload: '' })
  };

  return (
    <Dialog
      open={!!messageError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paperDialog,paperScrollPaper:classes.paperScrollPaper, root: classes.boxDialog }}
    >
      <DialogContent classes={{ root: classes.containerText }}>
        <p className={classes.textTitle}>{ messageError }</p>
      </DialogContent>
      <Grid container justifyContent="flex-end">
        <Button onClick={handleClose} classes={{ root: clsx(classes.buttons, classes.buttonDelete) }}>
          {btnName ? 'OK' : 'Close'}
        </Button>
      </Grid>
    </Dialog>
  );
})

export default PopupMessageError
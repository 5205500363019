import produce from 'immer';
import {
  SET_PRO_USER_QUERY
} from './actionTypes';

export interface IUserState {
  query: {
    pageSize: number,
    page: number,
    keyword: string,
    sortables: {fieldName: string, isDescending: boolean}[]
  }
}

const initial: IUserState = {
  query: {
    pageSize: 20,
    page: 1,
    keyword: '',
    sortables:[{fieldName: 'lastName', isDescending: false}]
  }
};

export const userReducers = (state = initial, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PRO_USER_QUERY: {  
        draft.query = {...action.payload}
        return draft;
      }
      default: return draft;
    }
  });


import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiListSubheader-gutters": {
        padding: 0,
        lineHeight: "14.4px",
        margin: '10px 0',
        marginLeft: 35,
        fontSize: "14px",
        // fontFamily: "Roboto !important",
        fontWeight: 500,
        color: "grey",
        "& p": {
          margin: '0',
          padding: '0'
        }
        // fontFamily:'A'
      },
      "& .MuiListItem-gutters ": {
        paddingLeft: 49,
        height: 34,
        paddingRight: 10,

        "& div>span": {
          fontSize: "14px !important",
          fontFamily: "Roboto !important",
          fontWeight: 400,
          color: "#FFFFFF",
        },
      },
    },
    rootOwner: {
      height: "calc(100% - 190px)",
      "& .MuiListSubheader-gutters": {
        padding: 0,
        color: "grey",
        // opacity: "60%",
        fontSize: 14,
        fontWeight: 500,
        // textAlign: 'center',
        lineHeight: "14.4px",
        marginBottom: 10,
        marginLeft: 35,
        // fontFamily:'A'
      },
      "& .MuiListItem-gutters ": {
        paddingLeft: 49,
        height: 34,
        paddingRight: 10,

        "& div>span": {
          fontSize: "14px !important",
          fontFamily: "Roboto !important",
          fontWeight: 400,
          color: "#FFFFFF",
        },
      },
    },
    rootUser: {
      height: "50px",
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      "& .MuiListSubheader-gutters": {
        padding: 0,
        color: "#FFFFFF",
        opacity: "40%",
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "14.4px",
        marginBottom: 10,
        marginLeft: 23,
        // fontFamily:'A'
      },
      "& .MuiListItem-gutters ": {
        "& img": {
          marginLeft: '-7px',
          marginRight: '10px',
        },
        paddingLeft: 36,
        height: 45,
        paddingRight: 10,
        backgroundColor: 'rgba(0,0,0,0.5)',
        // paddingBottom: '2px',

        "& div>span": {
          fontSize: "14px !important",
          fontFamily: "Roboto !important",
          fontWeight: 400,
          color: "#FFFFFF",
        },
      },
    },
    itemActive: {
      "& span": { color: "#83F695 !important" },
      backgroundColor: "#0B1F22",
      boxSizing: "border-box",
      borderRight: "3px solid #83F695",
    },
    searchOwner: {
      display: "flex",
      justifyContent: "center",
    },
    ownerContent: {
      height: "calc(100% - 50px)",
      overflow: "auto",
    },
  })
);

import React from "react";
import errorImage from '../../asset/img/error-img.svg'
import "./error.css";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // It will update the state so the next render shows the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // It will catch error in any component below. We can also log the error to an error reporting service.
    console.log("error : ", error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="heading">
            <div className="img-container">
              <img src={errorImage} alt="error" />
            </div>
            <div className="err-msg">
              <p className="err-msg-font">Oops! Something went wrong</p>
              <p className="err-sub-msg-font">Please try again</p>
            </div>
            <button class="glow-on-hover" type="button" onClick={() => { window.location.reload() }}>
              Go back
            </button>
            {/* </Link> */}
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

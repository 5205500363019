// main text
const mainTextLogin = '#222222';
const mainTitleLogin = '#FFFFFF';

const mainText = '#999999';
const mainTextLight = 'rgba(255, 255, 255, 0.75)';
const titleColor = '#FFFFFF';
const titleLightColor = '#E5E5E5';
const bgColor = '#182390';
const bgLightColor = '#385B7F';
const bgDarkColor = '#012d5d';
const secondaryBgColor = '#989898';
const secondaryLightBgColor = '#CFD7E0';
const secondaryDarkBgColor = '#8d949b';
const neutralBgColor = 'rgba(196, 196, 196, 0.15)';
const neutralLightBgColor = '#636566';
const mainColor = '#193d65';
const mainDarkColor = '#01254d';
const mainLightColor = '#869BB1';
const highLightChartColor = '#14D3A5';
const highLightColor = '#46D8D5';
const lightBlueColor = '#40E8FF';
const purpleColor = '#6E6B98';
const bgButtonColor = '#012855';
const bgButtonLightColor = '#19426D';
const menuColor = '#141c73';
const menuLightColor = '#464fa6';
const menuDarkColor = '#0E3864';
const orangeColor = '#E9781C';
const redColor = '#B30909';
const redChartColor = '#FF4F4F';
const yellowChartColor = '#F6E232';
const bgChartColor = '#17456B';
const bgTooltipColor = '#000000';
const bgDropdown = '#0D3F6A';

//Font Family
const Montserrat = 'Montserrat';
const Roboto = 'Roboto';

const themeConfig = {
  mainText,
  mainTextLight,
  titleColor,
  titleLightColor,
  bgColor,
  bgLightColor,
  bgDarkColor,
  secondaryBgColor,
  secondaryLightBgColor,
  secondaryDarkBgColor,
  neutralBgColor,
  neutralLightBgColor,
  mainColor,
  mainDarkColor,
  mainLightColor,
  highLightColor,
  highLightChartColor,
  lightBlueColor,
  purpleColor,
  bgButtonColor,
  bgButtonLightColor,
  menuColor,
  menuLightColor,
  menuDarkColor,
  orangeColor,
  redColor,
  redChartColor,
  yellowChartColor,
  bgChartColor,
  bgTooltipColor,
  bgDropdown,

  //Font
  Montserrat,
  Roboto,

  
  text: mainTextLogin,
  title: mainTitleLogin,

  //other
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1290,
    xxl: 1570
  },
};

export default themeConfig;
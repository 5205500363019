import React, {memo} from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = memo(({ component, isLoggedIn, urlRedirect, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) =>
      isLoggedIn ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={urlRedirect} />
      )
    }
  />
));

export default PrivateRoute;

import { makeStyles } from "@material-ui/core/styles";
import theme from "config/theme";

interface styleProps {
  marginBottom: string;
  height: number;
  pHSize: number;
}

const useStyles = makeStyles({
  rootFormControl: {},
  container: {
    marginBottom: (props: styleProps) => props.marginBottom || "5%",
  },
  rootTextField: {
    width: "100%",
    "& > div": {
      background: "rgba(255, 255, 255, 0.25)!important",
      borderRadius: 5,
      height: (props: styleProps) => props.height || 45,
      "& input": {
        padding: "20px 12px 20px",
        borderRadius: 5,
        color: "#FFFFFF",
        // fontFamily: "Montserrat-Regular !important",
        marginLeft: 8,
        "& focus ": {
          boxShadow: "none",
        },
      },
    },
    "& > .MuiFilledInput-multiline": {
      padding: 0,
      borderRadius: 5,
      color: "#FFFFFF",
      // fontFamily: "Montserrat-Regular !important",
      "& focus ": {
        boxShadow: "none",
      },
    },
  },
  label: {
    color: "#FFFFFF",
    marginBottom: 4,
    // fontFamily: "Montserrat-Regular !important",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: 14,
    letterSpacing: "0.25px",
  },
  textError: {
    marginTop: 5,
    fontSize: "14px !important",
    color: "#DE4C73 !important",
  },
  inputTextfield: {
    height: (props: styleProps) => {
      return `${props.height} !important` || 20;
    },
    "&::-ms-reveal,::-ms-clear": {
      display: "none",
    },
    "&::placeholder": {
      opacity: 1,
      color: "#999999",
      // fontFamily: "Montserrat-Regular !important",
      fontSize: (props: styleProps) => props.pHSize || 16,
      lineHeight: 22,
      fontWeight: 400,
    },
    "&:-webkit-autofill": {
      "-webkit-text-fill-color": `${theme.titleColor} !important`,
      "-webkit-background-clip": "text",
      filter: "none",
      "&:hover, &:focus, &:active": {
        caretColor: theme.titleColor,
      },
    },
    "&:-webkit-autofill::first-line": {
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: 400,
      // fontFamily: "Montserrat-Regular !important",
      borderRadius: 5,
      height: 45,
    },
    padding: "19px 12px 15px",
    fontSize: 16,
    fontWeight: 400,
  },
  buttonIcon: {},
  icOffShowPass: {
    width: 24,
    height: 24,
    "& img": {
      width: 18,
      height: 18,
    },
  },
  inputPr: {
    paddingRight: 12,
  },
  bg25: {
    "& > div": {
      background: "#23373B !important",
    },
  },
});

export default useStyles;

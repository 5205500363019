import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      paperScrollPaper: {
        backgroundColor: "#0F292E",
      },
      paperDialog: {
        width: 579,
        maxWidth: "inherit",
        borderRadius: 10,
        padding: 30,
      },
      containerText: {
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "normal",
        letterSpacing: "0.05em",
        paddingTop: "0 !important",
      },
      buttons: {
        width: 150,
        height: 40,
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "normal",
        letterSpacing: "0.05em",
        margin: "0 8px",
        color: "#FFFFFF",
        borderRadius: 5,
        padding: 22,
      },
      ".MuiDialogContent-root:first-child": {
        paddingTop: 0,
      },
      textTitle: {
        color: "#FFFFFF",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
      },
      buttonDelete: {
        background: "rgba(223, 223, 223, 0.25)",
        "&:hover": {
          background: "rgba(223, 223, 223, 0.35)",
        },
      },
      buttonSuccess: {
        background: "rgba(20, 211, 165, 0.25)",
        // border: "1px solid #83F695",
        "&:hover": {
          background: "rgba(20, 211, 165, 0.3)",
        },
      },
      boxDialog: {
        "& > div": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      },
    }),
  {
    name: "PopupMessageConfirm",
    index: 1,
  }
);

export default useStyles;

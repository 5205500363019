import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS } from 'store/reducers/profile/actionTypes';
import { SET_LOADING_PAGE, SET_MESSAGE_ERROR } from 'store/reducers/global/actionTypes';
import { getMyProfile } from 'services/profile';
import * as CONSTANTS from 'config/constans';
import { AxiosError } from 'axios';

function* getProfileRequest() {
  try {
    yield put({ type: SET_LOADING_PAGE, payload: true })
    const token = localStorage.getItem(CONSTANTS.ACCESS_TOKEN) || '';
    const profile = yield call(getMyProfile, token);

    yield put({type: GET_PROFILE_SUCCESS, payload: {...profile}})
    yield put({ type: SET_LOADING_PAGE, payload: false })
  } catch(error) {
    const err = error as AxiosError
    yield put({ type: SET_MESSAGE_ERROR, payload: err.message })
    yield put({ type: SET_LOADING_PAGE, payload: false })
  }
  
}

function* getProfileSaga() {
  yield takeLatest(GET_PROFILE_REQUEST, getProfileRequest);
}

export default getProfileSaga

import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      background: "#0C2125",
      // opacity: 0.2,
      display: "flex",
      justifyContent: "space-between",
      padding: 16,
      boxSizing: "border-box",
      height: 56,
      alignItems: "center",
    },
    profile: {
      width: `calc(100% - 28px)`,
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      width: 23,
      height: 23,
    },
    name: {
      marginLeft: 9,
      color: "#FFFFFF",
      fontSize: 14,
      fontFamily: "Roboto !important",
      textTransform: 'capitalize',
      fontWeight: 500,
    },
    subName: {
      marginLeft: 9,
      color: "grey",
      fontSize: 10,
      fontFamily: "Roboto !important",
    },
    iconMenu: {
      cursor: "pointer",
    },
  })
);

import produce from 'immer';
import * as actions from './actionTypes';

export interface globalState {
  messageError: string;
  isLoading: boolean;
  loadingFollow: boolean,
  btnNameShow: boolean
}

const initial: globalState = {
  messageError: '',
  isLoading: false,
  loadingFollow: false,
  btnNameShow:false
};

export const globalReducer = (state = initial, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.SET_LOADING_PAGE:
        draft.isLoading = action.payload;
        break;
      case actions.SET_MESSAGE_ERROR:
        draft.messageError = action.payload;
        break;
      case actions.SET_LOADING_FOLLOW: {
        draft.loadingFollow = action.payload;
        break;
      }
      case actions.SET_TEXT_SHOW_POPUP: {
        draft.btnNameShow = action.payload;
        break;
      }
      default: return draft
    }
  });

const exports = {
  globalReducer
}
export default exports

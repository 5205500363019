import { memo } from 'react';
import {
  Button, Dialog,
  DialogContent, Grid
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

interface PopupMessageConfirmProps {
  message: string;
  handleClose?: () => void,
  handleSuccess?: () => void,
}

const PopupMessageConfirm = memo((props: PopupMessageConfirmProps) =>  {
  const { message, handleClose, handleSuccess } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={!!message}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paperDialog,paperScrollPaper:classes.paperScrollPaper, root: classes.boxDialog }}
    >
      <DialogContent classes={{ root: classes.containerText }}>
        <p className={classes.textTitle}>{ message }</p>
      </DialogContent>
      <Grid container justifyContent="flex-end">
        <Button onClick={handleClose} classes={{ root: clsx(classes.buttons, classes.buttonDelete) }}>
          Cancel
        </Button>
        <Button onClick={handleSuccess} classes={{ root: clsx(classes.buttons, classes.buttonSuccess) }}>
          OK
        </Button>
      </Grid>
    </Dialog>
  );
})

export default PopupMessageConfirm
import React, { memo } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";

interface ButtonsProps {
  btnType?: any;
  onClick?: () => void;
  type?: "button" | "reset" | "submit" | undefined;
  children: any;
  component?: React.ElementType<any>;
  disable?: boolean;
  styles?: any;
  width?: number;
}
const Buttons = memo((props: ButtonsProps) => {
  const classes = useStyles(props);
  const {
    btnType,
    styles,
    children,
    type = "submit",
    component = "button",
    onClick,
    disable,
  } = props;
  return (
    <Button
      className={clsx(
        classes.root,
        btnType === "large" && classes.btnLarge,
        btnType === "medium" && classes.btnMedium,
        btnType === "small" && classes.btnSmall,
        btnType === "smallLightBg" && classes.btnSmallLightBg,
        btnType === "smallLong" && classes.btnSmallLong,
        btnType === "back" && classes.btnBack,
        btnType === "save" && classes.btnSave,
        btnType === "close" && classes.btnClose,
        styles || ""
      )}
      type={type}
      onClick={onClick}
      component={component}
      disabled={disable}
    >
      {children}
    </Button>
  );
});
export default Buttons;

export interface User {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  owner: string;
  ownerId: number;
  role: string;
  roleId: number;
  status: number;
}

export enum UserTypeID {
  Admin = 1,
  Owner = 2,
}
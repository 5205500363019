import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      "& img": {
        marginRight: 9,
      },
      "& .primary": {
        color: "#FFFFFF",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "20px",
        letterSpacing: "3%",
        fontFamily: "CeraPro-Medium !important",
      },
      "& .second": {
        color: "#91BF82",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: 300,
        fontFamily: "CeraPro-Light !important",
      },
    },
  })
);

export const API = {
  USER: {
    LOGIN: '/api/services/account/login',
    REFRESH_TOKEN: '/api/services/account/cms/refreshToken',
    RESET_PASSWORD: '/api/services/account/forgotPassword',
    UPDATE_PASSWORD: '/api/services/account/updateForgotPassword',
    PROFILE: '/api/services/account/profile'
  },
  OWNER: {
    SEARCH: '/api/services/account/cms/owner/search',
    CREATE: '/api/services/account/cms/owner/create',
    UPDATE: '/api/services/account/cms/owner/update',
    UPDATE_STATUS: '/api/services/account/cms/owner/updateStatus'
  },
  USERS: {
    SEARCH: '/api/services/account/cms/search',
    CREATE: '/api/services/account/cms/create',
    UPDATE: '/api/services/account/cms/update',
    DELETE: '/api/services/account/cms/delete',

  },
  VESSEL: {
    SEARCH: '/api/services/vesseldata/cms/search',
    CREATE: '/api/services/vesseldata/cms/create',
    UPDATE: '/api/services/vesseldata/cms/update',
    DELETE: '/api/services/vesseldata/cms/delete',
    UPDATE_STATUS: '/api/services/vesseldata/cms/updateStatus',
    TRACKING: '/api/services/vesseldata/cms/getVesselByOwnerId',
    CHANNEL: '/api/services/channel/cms/getAll',
    UPDATE_CHANNEL: '/api/services/channel/cms/updateChannel',
    CHANNEL_BYID: '/api/services/vesseldata/cms/getChannelById',
    LIST_IMO: '/api/services/vesseldata/cms/getAvailableImo',
    UPDATE_VESSEL_CHANNEL: '/api/services/vesseldata/cms/updateVesselChannel',
    VESSEL_ALLERTS_SEVERITY: '/api/services/vesseldata/cms/GetVesselAlertsBySeverity',
    VESSEL_ALLERTS_CHANNEL: '/api/services/vesseldata/cms/GetVesselAlertsByChannel',
    VESSEL_LIST_FOR_ALLERTS: '/api/services/vesseldata/cms/getVesselAlertlist',
    VESSEL_LIST_FOR_ALLERTS_MENU: '/api/services/vesseldata/cms/getVesselListByOwnerId',
    VESSEL_DATA_TABLE_VIEW: '/api/services/vesseldata/cms/getVesselAlertByVesselId',
    VESSEL_FOR_MAP_VIEW: '/api/services/vesseldata/cms/getVesselsForMapView',
    NOON_REPORT_GRAPH_LAST_MONTH: '/api/services/analyticsreport/cms/lastmonth',
    NOON_REPORT_GRAPH_LAST_WEEK: '/api/services/analyticsreport/cms/lastweek',
    NOON_REPORT_GRAPH_CUSTOM: '/api/services/analyticsreport/cms/custom',
    NOON_REPORT_TABLE_LIST: '/api/services/analyticsreport/cms/getAll',
    ADD_NOON_REPORT_GRAPH: '/api/services/analyticsreport/cms/insert',
    EDIT_NOON_REPORT_GRAPH: '/api/services/analyticsreport/cms/edit',
    GET_NOON_REPORT_GRAPH: '/api/services/analyticsreport/cms/getDetailById',
    DETELE_NOON_REPORT_GRAPH: '/api/services/analyticsreport/cms/deleteById',
  },
  LISTING: {
    LIST: '/api/services/vesseldata/search',
    PAST_VOYAGES: 'api/services/vesseldata/voyage',
    EXPORT: '/api/services/exportpdf/vesselListing',
    EXPORT_BY_IMO: '/api/services/exportpdf/vesselDataByImo',
    EXPORT_CSV_BY_IMO: '/api/services/exportcsv/vesselDataByImo',
  },
  MONITOR: {
    HEADER: '/api/services/vesseldata/getTotalCO2Data',
    HOUR: '/api/services/vesseldata/getCO2perHourData',
    DAY: '/api/services/vesseldata/getCO2perDayData',
    FLOW: '/api/services/vesseldata/getCO2FlowData',
    PERCENT: '/api/services/vesseldata/getCO2percentData',
    PRESSURE: '/api/services/vesseldata/getCO2PressureData',
    TEMPERATURE: '/api/services/vesseldata/getCO2TemperatureData',
    LINE_DAY: '/api/services/vesseldata/getCO2EmissionDataLast24Hour',
    LINE_WEEK: '/api/services/vesseldata/getCO2EmissionDataLastWeek',
    LINE_RANGE: '/api/services/vesseldata/getCO2EmissionDataCustomRange',
    AMOUNT_CHANNEL: '/api/services/vesseldata/cms/getChannelByImo',
    GET_DATE_ASYNC: '/api/services/vesseldata/cms/getLastSyncDateTime',
    RE_FRESH_DATE_ASYNC: '/api/services/vesseldata/cms/getRefreshData'
  },
  ROLE: {
    SEARCH: '/api/services/account/cms/role/search'
  }
}
export const isRefreshing = 'isRefreshing'
export const ACCESS_TOKEN = 'ACCESS_TOKEN_APP'
export const REFRESH_TOKEN = 'REFRESH_TOKEN_APP'

export const patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const patternPassword = /(?=^.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])([0-9a-zA-Z!@#$%^&*()])*$/;

export const GmtTimeZones = [
  { name: '-11:00', value: "-11:00" },
  { name: '-10:00', value: "-10:00" },
  { name: '-9:00', value: "-9:00" },
  { name: '-8:00', value: "-8:00" },
  { name: '-7:00', value: "-7:00" },
  { name: '-6:00', value: "-6:00" },
  { name: '-5:00', value: "-5:00" },
  { name: '-4:00', value: "-4:00" },
  { name: '-3:00', value: "-3:00" },
  { name: '-2:00', value: "-2:00" },
  { name: '-1:00', value: "-1:00" },
  { name: '0:00', value: "0:00" },
  { name: '+1:00', value: "+1:00" },
  { name: '+2:00', value: "+2:00" },
  { name: '+3:00', value: "+3:00" },
  { name: '+4:00', value: "+4:00" },
  { name: '+5:00', value: "+5:00" },
  { name: '+6:00', value: "+6:00" },
  { name: '+7:00', value: "+7:00" },
  { name: '+8:00', value: "+8:00" },
  { name: '+9:00', value: "+9:00" },
  { name: '+10:00', value: "+10:00" },
  { name: '+11:00', value: "+11:00" },
]
export const ReportType = [
  { name: 'Arrival', value: 'Arrival' },
  { name: 'Departure', value: 'Departure' },
  { name: 'Position', value: 'Position' },
]
export const NoonReportActivities = [
  { name: 'Propulsion', value: 'Propulsion' },
  { name: 'Standard Aux', value: 'Standard Aux' },
  { name: 'Heating', value: 'Heating' },
  { name: 'Inerting', value: 'Inerting' },
]
export const NoOfAux = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
]
export const AlertFilter = [
  { name: 'None', value: false },
  { name: 'Channels', value: 'Channel' },
  { name: 'Others', value: 'OTHER' },
]
export const AlertColor = [
  { name: 'None', value: false },
  { name: 'Severe', value: '#FF0000' },
  { name: 'Mild', value: '#FFFF00' },
]

export const LATITUDE_DIR = [
  { name: 'N', value: 'N' },
  { name: 'S', value: 'S' },
]
export const LONGITUDE_DIR = [
  { name: 'E', value: 'E' },
  { name: 'W', value: 'W' },
]

import { memo } from 'react';
import useStyles from './styles';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

interface TextsProps {
  children: any;
  onClick?: any;
  styles?: any;
  color?: string;
  size?: string;
  fontWeight?: string;
}

const Texts = memo((props: TextsProps) => {
  const { children, onClick, styles, color, size, fontWeight } = props;
  const classes = useStyles(); 

  return (
    <Typography
      className={clsx(classes.root,
        color === 'normal' ? classes.colorNormal : '', 
        size === 'large' ? classes.sizeLarge : '',
        size === '14' ? classes.size14 : '',
        size === '18' ? classes.size18 : '',
        size === '20' ? classes.size20 : '',
        size === '22' ? classes.size22 : '',
        size === '24' ? classes.size24 : '', 
        size === '30' ? classes.size30 : '', 
        size === '32' ? classes.size32 : '', 
        fontWeight === '500' ? classes.fontWeight500 : '',
        fontWeight === '300' ? classes.fontWeight300 : '',
        fontWeight === '600' ? classes.fontWeight600 : '',
        fontWeight === 'bold' ? classes.fontWeightBold : '',
        styles || '',
      )}
      onClick={onClick}
      >
       {children}
    </Typography>
  );
})

export default connect()(Texts);


import { LoadingFullPage } from "components";
import LayoutCms from "components/LayoutCms";

import { lazy, memo, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { routes } from "./routes";
import { IProfileState } from "store/reducers/profile";
import { UserTypeID } from "models/user";
const Authentication = lazy(() => import("modules/Authentication"));
const ForgotPassword = lazy(() =>
  import("modules/Authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("modules/Authentication/ResetPassword")
);
const OwnerVesselTableMap = lazy(() => import("modules/Owner-User/VesselTableMap"));
const Dashboard = lazy(() => import("modules/Common/Dashboard"));
const AlertSummary = lazy(() => import("modules/Common/AlertSummary"));
const NoonReport = lazy(() => import("modules/Common/NoonReport"));

interface IRoutes {
  isLoggedIn: boolean;
  profile: IProfileState;
}

const Routes = memo((props: IRoutes) => {
  const { profile } = props;
  const isAdmin = profile.user_metadata.user_type_id === UserTypeID.Admin.toString();

  return (
    <Suspense fallback={<LoadingFullPage isShow={true} isFullPage={true} />}>
      <Switch>
        {/* Authentication routes */}
        <Route path={routes.forgotPassword} component={ForgotPassword} />
        <Route path={routes.resetPassword} component={ResetPassword} />
        <PrivateRoute
          exact
          path={routes.authentication}
          component={Authentication}
          urlRedirect={routes.default}
          isLoggedIn={!props.isLoggedIn}
        />

        {/* Owner user routes */}
        <PrivateRoute
          exact
          path={routes.listing}
          component={OwnerVesselTableMap}
          urlRedirect={
            !props.isLoggedIn ? routes.authentication : routes.default
          }
          isLoggedIn={props.isLoggedIn && !isAdmin}
        />

        {/* Owner-admin common routes */}
        <PrivateRoute
          exact
          path={routes.noonReport}
          component={NoonReport}
          urlRedirect={
            routes.authentication
          }
          isLoggedIn={props.isLoggedIn}
        />

        <PrivateRoute
          exact
          path={routes.alertSummary}
          component={AlertSummary}
          urlRedirect={
            routes.authentication
          }
          isLoggedIn={props.isLoggedIn}
        />

        <PrivateRoute
          exact
          path={routes.monitor}
          component={Dashboard}
          urlRedirect={routes.authentication}
          isLoggedIn={props.isLoggedIn}
        />

        {/* Admin user content routes */}
        <PrivateRoute
          path={routes.default}
          component={LayoutCms}
          urlRedirect={
            !props.isLoggedIn ? routes.authentication : routes.listing
          }
          isLoggedIn={props.isLoggedIn && !!isAdmin}
        />
      </Switch>
    </Suspense>
  );
});

export default connect(null)(Routes);

import produce from 'immer';
import {
  SET_PRO_VESSEL_QUERY
} from './actionTypes';

export interface IVesselState {
  query: {
    pageSize: number,
    page: number,
    keyword: string,
    search: { keyword: string,  fieldName: string }[],
    sortables: {fieldName: string, isDescending: boolean}[]
  }
}

const initial: IVesselState = {
  query: {
    pageSize: 20,
    page: 1,
    keyword: '',
    search: [
      { keyword: '', fieldName: 'Name'},
      { keyword: '', fieldName: 'Owner'},
    ],
    sortables:[{fieldName: 'vesselName', isDescending: false}]
  }
};

export const vesselReducers = (state = initial, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_PRO_VESSEL_QUERY: {  
        draft.query = {...action.payload}
        return draft;
      }
      default: return draft;
    }
  });


import { patternPassword } from 'config/constans';
import moment from 'moment';

export const checkTypeSort = (key: string, query: { sortBy?: string, sortOrder?: string }): any => {
  if (query.sortBy !== key) return '';
  if (query.sortOrder === 'asc') return 'az';
  return 'za'
}

export const getNewSort = (key: string, sortBy?: string, sortOrder?: string) => {
  if (sortBy === key)
    return {
      sortBy: key,
      sortOrder: sortOrder === 'asc' ? 'desc' : 'asc'
    }
  else {
    return {
      sortBy: key,
      sortOrder: 'asc'
    }
  }
}

export const formatNumber = (num: number | string, decimalCount = 2, thousands = ",") => {
  if (!num) return 0;
  const n = 'string' === typeof num ? (+num) : num;
  if (num === -0) return 0;
  if (!decimalCount) {
    let result = ((n).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, `$&${thousands}`)).replace('.0', '');
    return result !== '-0' ? result : '0'
  }
  let result = (n).toFixed(decimalCount).replace(/\d(?=(\d{3})+\.)/g, `$&${thousands}`).replace(new RegExp(`\\.0{${decimalCount}}$`, 'g'), '');
  return result !== '-0' ? result : '0'
}

export const getPageAfterDelete = (totalCurrent: number, pageCurrent: number, pageSize: number, num: number) => {
  let page = pageCurrent;
  if (pageCurrent === num) page = page - 1;
  else {
    const totalNew = totalCurrent - num;
    const maxPage = Math.ceil(totalNew / pageSize);
    if (pageCurrent > maxPage) page = page - 1;
  }
  if (page <= 0) page = 1;
  return page;
}

export const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    console.log('Async: Copying to clipboard was successful!');
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export const parseJwt = (token: string = '') => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  try {
    return JSON.parse(jsonPayload)
  } catch (e) {
    return {}
  }
};

const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export const sleep = (ms: number) => {
  return new Promise(resole => setTimeout(resole, ms))
}

export const titleCase = (text: string | undefined) => {
  if (!text) return;
  let sentence = text.match(/[A-Z]([a-z]*)+/g);
  if (sentence) {
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  }
}

export const validatePassword = (value) => {
  if (value.length === 0) {
    return true
  }
  if (value.length < 8) {
    return `Password must be at least 8 characters`
  }
  if (value.length >= 8 && value.length <= 20) {
    if (!patternPassword.test(value)) {
      return `Password invalid. At least one uppercase English letter, At least one lower case, At least one special character, At least one digit`
    }
  }
  if (value.length > 20) {
    return `Maximum of 20 characters`
  }
}

export const convertArrayToString = (arr: any[], key: string = 'name', jon: string = ', ') => {
  return arr.map(it => it[key]).join(jon)
}


export const kFormatter = (num: number, decimalCount: number = 2) => {
  let result: any = Math.sign(num) * Math.abs(num);
  if (Math.abs(num) > 999) {
    result = (Math.sign(num) * (Math.abs(num) / 1000))
    result = formatNumber(result, decimalCount);
    result = result.replace(/\.0+$/, '')
    result += 'k';
    return result;
  } else return formatNumber(num, decimalCount);
}

export function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
  return degrees + '° ' + minutes + "' " + seconds + "''";
}

export function convertLatTODMS(lat) {
  console.log('lat: ', lat);
  let latitude = toDegreesMinutesAndSeconds(lat);
  let latitudeCardinal = ""
  if (!(lat > -1 && lat < 1))
    latitudeCardinal = lat >= 0 ? "N" : "S";

  return latitude + " " + latitudeCardinal;
}
export function convertLongTODMS(lng) {

  let longitude = toDegreesMinutesAndSeconds(lng);
  let longitudeCardinal = ""
  if (!(lng > -1 && lng < 1))
    longitudeCardinal = lng >= 0 ? "E" : "W";

  return longitude + " " + longitudeCardinal;
}
export function convertZeroDegreeToFloat(cooridinate) {
  if (cooridinate === 0) {
    return 0.0000
  } else if (cooridinate === -0) {
    return -0.0000
  } else {
    return cooridinate
  }
}
export const customDateFunction = (datestring) => {
  let labelArray = datestring?.split('*') || [];
  return `${moment(labelArray?.[0]).format('DD MMMM HH:mm')} - ${moment(labelArray?.[1]).format('DD MMMM HH:mm')}`
}
interface Routes {
  authentication: string;
  default: string;
  monitor: string;
  notFound: string;
  forgotPassword: string;
  resetPassword: string;
  listing: string;
  alertSummary: string;
  noonReport: string;
  admin: {
    default: string;
    vessel: string;
    user: string;
    owner: {
      default: string;
      vessel: string;
    };
  };
}

export const routes: Routes = {
  default: "/",
  resetPassword: '/reset-password',
  monitor: "/monitor/:imo",
  authentication: "/login",
  notFound: "/not-found-page",
  forgotPassword: '/forgot-password',
  listing: "/listing",
  alertSummary: "/alert-summary",
  noonReport: "/analytics",
  admin: {
    default: '/admin',
    vessel: "/admin/vessel",
    user: "/admin/user",
    owner: {
      default: "/admin/owner",
      vessel: "/admin/owner/:ownerId",
    },
  },
};

import produce from 'immer';
import {
  GET_PROFILE_SUCCESS
} from './actionTypes';
export interface IProfileState {
  created_at: Date;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  last_login: Date;
  name: string;
  nickname: string;
  updated_at: Date;
  user_metadata: {
    account_id: string,
    user_type_id: string,
    owner_name: string;
    owner_id?: string;
  }
}

const initial: IProfileState = {
  created_at: new Date(),
  email: '',
  email_verified: false,
  family_name: '',
  given_name: '',
  last_login: new Date(),
  name: '',
  nickname: '',
  updated_at: new Date(),
  user_metadata: {
    account_id: '',
    user_type_id: '',
    owner_name: '',
  }
};

export const profileReducer = (state = initial, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PROFILE_SUCCESS: {
        const profile = { ...action.payload };
        draft = { ...profile }
        return draft;
      }
      default: return draft;
    }
  });


import { Avatar, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

import { useSelector } from 'react-redux';
import { IProfileState } from 'store/reducers/profile';
import { reducerType } from "store/reducers";

const Profiles = () => {
  const classes = useStyles();
  const profile: IProfileState = useSelector((state: reducerType) => state.profile);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.profile}>
        <Avatar className={classes.avatar} />
        <div>
          <Typography className={classes.name}>{profile.nickname}</Typography>
          <Typography className={classes.subName}>Admin</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Profiles;
